import log from '@mc/es-logger';

const FCP_ENTRY_NAME = 'first-contentful-paint';
const FCP_ENTRY_TYPE = 'paint';
const LCP_ENTRY_TYPE = 'largest-contentful-paint';

const channelNameMap = new Map([
  [FCP_ENTRY_NAME, 'performance_fcp'],
  [LCP_ENTRY_TYPE, 'performance_lcp'],
]);

const messageMap = new Map([
  [FCP_ENTRY_NAME, 'FCP measured'],
  [LCP_ENTRY_TYPE, 'LCP measured'],
]);

declare global {
  interface Window {
    i18n_intl_updated_state_values?: boolean;
    i18n_update_translation_fetching?: boolean;
    i18n_update_translation_data?: boolean;
  }
}

type EntryData = PerformancePaintTiming | LargestContentfulPaint;

interface LogData {
  className?: string;
  parentClassName?: string;
  startTime: number;
  textContent?: string | null;
  viewport: string;
  userAgent: string;
  isAccurate?: boolean;
}

let mostRecentEntry: PerformanceEntry;
let isDelayReached: boolean;

export function hasValidEntries(entryType: string): boolean {
  return (
    // Don't proceed if PerformanceObserver or entry type is unsupported
    'PerformanceObserver' in window &&
    PerformanceObserver.supportedEntryTypes.includes(entryType) &&
    // Don't record if we're loading in the background
    document?.visibilityState === 'visible'
  );
}

const collectEntryData = (entry: EntryData): LogData => {
  return {
    className: (entry as LargestContentfulPaint)?.element?.className,
    parentClassName: (entry as LargestContentfulPaint)?.element?.parentElement
      ?.className,
    startTime: entry?.startTime,
    textContent: (entry as LargestContentfulPaint)?.element?.textContent,
    viewport: `${window?.innerWidth}x${window?.innerHeight}`,
    // NOTE - I'm copying this data over from the original lcp-logger module but this is most likely NOT allowable data to record without user permission
    userAgent: navigator.userAgent,
    // NOTE - older logs for lcp may refer to this as 'isAccurateLCP'
    isAccurate: (entry as LargestContentfulPaint)?.renderTime ? true : false,
  };
};

const recordLog = (entryTypeOrName: string, data: LogData): void => {
  // all applicable flags ON
  const i18nCompositeFlagOn =
    window.i18n_intl_updated_state_values &&
    window.i18n_update_translation_fetching &&
    window.i18n_update_translation_data;
  // all applicable flags OFF
  const i18nCompositeFlagOff = !(
    window.i18n_intl_updated_state_values ||
    window.i18n_update_translation_fetching ||
    window.i18n_update_translation_data
  );

  log({
    channelName: channelNameMap.get(entryTypeOrName) || '',
    message: messageMap.get(entryTypeOrName) || '',
    data: {
      route: {
        pathname: window.location.pathname,
      },
      flags: {
        i18n_composite_flag_on: i18nCompositeFlagOn,
        i18n_composite_flag_off: i18nCompositeFlagOff,
      },
      data,
    },
    classification: 'sensitive',
  });
};

const handleLcpEntries = (list: PerformanceObserverEntryList): void => {
  const entries = list.getEntries();
  mostRecentEntry = entries[entries.length - 1] || mostRecentEntry;
  // log LCP if delay has been reached during tracking
  if (isDelayReached) {
    recordLog(LCP_ENTRY_TYPE, collectEntryData(mostRecentEntry));
  }
};

const handleFcpEntries = (list: PerformanceObserverEntryList): void => {
  for (const entry of list.getEntries()) {
    if (entry.name === FCP_ENTRY_NAME) {
      recordLog(FCP_ENTRY_NAME, collectEntryData(entry));
      break;
    }
  }
};

/**
 * This utility logs FCP data to the "performance_fcp" channel
 *
 * Usage:
 * ```js
 * useEffect(() => {
 *   if (flag_for_small_temporary_cohort_is_on) {
 *     const observer = fcpObserver();
 *     return () => observer.disconnect();
 *   }
 * }, []);
 * ```
 */
export function fcpObserver(): PerformanceObserver | null {
  if (!hasValidEntries(FCP_ENTRY_TYPE)) {
    return null;
  }
  const observer = new PerformanceObserver(handleFcpEntries);
  observer.observe({ type: FCP_ENTRY_TYPE, buffered: true });

  return observer;
}

/**
 * This utility logs LCP events to the "performance_lcp" channel. It captures the element's
 * class name, parent class name and text content to help identify specific elements
 * responsible for largest contentful paint. Note that class names change for each build.
 *
 * Usage:
 * ```js
 * useEffect(() => {
 *   if (flag_for_small_temporary_cohort_is_on) {
 *     const observer = lcpObserver();
 *     return () => observer.disconnect();
 *   }
 * }, []);
 * ```
 *
 * For High Level components, you may pass a delay period in milliseconds
 * LCP logging will only be performed after the delay period has elapsed (if given)
 * The most recent lcp event will be provided for when logging is enabled
 * (ex-1, if 3 lcp events occur at 1s, 3s, & 4s - and the delay period is 5s; the 4s event will be logged)
 * (ex-2, if 3 lcp events occur at 1s, 3s, & 4s - and the delay period is 2s; the 4s event and the 3s event will be logged)
 * ```js
 * useEffect(() => {
 *   if (flag_for_small_temporary_cohort_is_on) {
 *     const observer = lcpObserver(5000);
 *     return () => observer.disconnect();
 *  }
 * }, []);
 * ```
 */
export function lcpObserver(msDelay?: number): PerformanceObserver | null {
  if (!hasValidEntries(LCP_ENTRY_TYPE)) {
    return null;
  }

  isDelayReached = false;

  // Set condition to only log LCP after the given delay period has elapsed
  if (msDelay) {
    setTimeout(() => {
      isDelayReached = true;
      // log the most recent lcp event if one exists directly after delay period
      if (mostRecentEntry) {
        recordLog(LCP_ENTRY_TYPE, collectEntryData(mostRecentEntry));
      }
    }, msDelay);
  } else {
    isDelayReached = true;
  }

  const observer = new PerformanceObserver(handleLcpEntries);
  observer.observe({ type: LCP_ENTRY_TYPE, buffered: true });

  return observer;
}
