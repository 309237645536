import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDateLocalizer } from 'i18n-formatting';
import { useUserData } from '@mc/user';
import { useMcIntl } from '@mc/internationalization/McIntlContext';
import { IntlErrorBoundary } from '@mc/internationalization/IntlErrorBoundary';
import {
  FORCE_DEFAULT_MESSAGE_ID,
  SUPPORTED_LOCALES,
} from '@mc/internationalization/constants';

/**
 * A custom hook, which returns a function (usually named `tt`) that can provide string translations.
 *
 * ```
 * import React from 'react';
 *
 * import { useTt } from '@mc/internationalization/translationUtils';
 *
 * function MyComponent() {
 *   const tt = useTt();
 *
 *   return (
 *     <img src="/dog-waving-hello.jpg" alt={tt({ id: "my_team.woof" })} />
 *   );
 * }
 * ```
 *
 * In simple this simple example, useTt is about as ergonomic as useTranslateMessage. The key advantage of useTt is that
 * it scales well to loops/Array.map in your component's JSX. Whereas with useTranslateMessage, you cannot provide per-
 * loop params to useTranslationMessage, because `tt` is separated out from the hook call, you can use it in the
 * Array.map callback, passing different param values each time.
 *
 * @returns {(function({id: *, values?: {}, defaultMessage?: string, description?: string}): (string|undefined))|*}
 */
export function useTt() {
  const { intl } = useMcIntl();

  return useCallback(
    ({ id, values = {}, defaultMessage = '', description = '' }) => {
      try {
        return intl.formatMessage({ id, defaultMessage, description }, values);
      } catch (error) {
        console.warn(
          'translationUtils: error thrown when trying to formatMessage to string',
          error,
        );
        return defaultMessage;
      }
    },
    [intl],
  );
}

/**
 * A React component which renders a translated message. Suitable for rendering JSX within a translated message.
 *
 * ```
 * import React from 'react';
 *
 * import { Tt } from '@mc/internationalization/translationUtils';
 * import { Text } from @mc/wink';
 *
 * function MyComponent() {
 *   return (
 *     <Text>
 *       <Tt
 *         id="my_team.view_terms_of_use"
 *         values={{ Link: (chunks) => (<a href="/terms">{chunks}</a>) }}
 *       />
 *     </Text>;
 *   );
 * }
 * ```
 *
 * ```
 * // data/nls/react/en.json
 * {
 *     "my_team.view_terms_of_use": "View the <Link>Terms of Use</Link>",
 * }
 * ```
 *
 * To flag an initial translation effort, do so outside this component:
 * ```
 * function MyComponent() {
 *   return (
 *     <Text>
 *       {isOn(FLAGS.TRANSLATE_MY_FEATURE) ? 'Original untranslated text' : <Tt id="my_team.my_key" />}
 *     </Text>
 *   );
 * }
 * ```
 * This approach also works when you want to flag changing from one key to another. It's also natively understood by the
 * flag remover codemod.
 */
export function Tt({ id, values = {}, defaultMessage = '', description = '' }) {
  return (
    <IntlErrorBoundary fallback={defaultMessage}>
      <FormattedMessage
        id={id}
        values={values}
        defaultMessage={defaultMessage}
        description={description}
      />
    </IntlErrorBoundary>
  );
}

Tt.propTypes = {
  defaultMessage: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export function useTranslateMessage({
  asComponent = false,
  defaultMessage,
  description,
  forceDefaultMessage = false,
  id,
  values,
}) {
  // We may want to force the defaultMessage at times, so we can just pass
  //  an invalid `id` to the component and it will render the `defaultMessage`
  //  instead of a translation from our key/value pairs.
  // This is usually set from the use of `withFlag` HOC where if the flag is off
  //  we are wanting to make sure that the user does not get the translation even if
  //  it exists
  const idToUse = forceDefaultMessage ? FORCE_DEFAULT_MESSAGE_ID : id;
  const { intl } = useMcIntl();

  if (asComponent) {
    return (
      <IntlErrorBoundary fallback={defaultMessage}>
        <FormattedMessage
          id={idToUse}
          defaultMessage={defaultMessage}
          values={values}
          description={description}
        />
      </IntlErrorBoundary>
    );
  }

  // We cannot use a component ErrorBoundary since this is supposed to only return a 'string'
  // Let's at least set up a try/catch to return the `defaultMessage` in the advent that something
  //   went wrong.
  try {
    const translatedString = intl.formatMessage(
      {
        id: idToUse,
        defaultMessage,
        description,
      },
      values,
    );
    return translatedString;
  } catch (error) {
    console.warn(
      'translationUtils: error thrown when trying to formatMessage to string',
      error,
    );
    return defaultMessage;
  }
}

/**
 * Formats a given date object to a human readable string based on the options passed in.
 * `formatDateAndOrTime` is internally created as a memoized callback function which returns the date as a string.
 * The return value of formatDateAndOrTime() is returned as the result of this hook.
 *
 * @param {Date} date - date object to format
 * @param {object} options - options to format the date, ie { year: 'numeric', month: 'long', day: 'numeric' }
 * @param {boolean} forceEnglishDate - whether or not to force the date to be in English
 * @returns {string}
 */
export function useFormatDateAndOrTime({
  date,
  options,
  forceEnglishDate = false,
}) {
  const { state } = useMcIntl();
  const { displayLocale } = state;

  const formatDateAndOrTime = useCallback(() => {
    try {
      let optionsToUse;
      // if no options are passed in, we'll default to a numeric date
      if (!options) {
        optionsToUse = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        };
      } else {
        optionsToUse = options;
      }
      const displayLocaleToUse = forceEnglishDate
        ? SUPPORTED_LOCALES.DEFAULT
        : displayLocale;
      // if we're only returning the date portion and timeStyle option is not passed in
      if (!optionsToUse.hour && !optionsToUse.timeStyle) {
        return date.toLocaleDateString(displayLocaleToUse, optionsToUse);
      }

      return date.toLocaleString(displayLocaleToUse, optionsToUse);
    } catch (error) {
      const isDateValid = date instanceof Date;
      const logText = isDateValid
        ? 'translationUtils: error thrown when trying to format date to string'
        : 'translationUtils: error thrown for invalid date param';
      console.warn(logText, error);
      // NOTE fallback text may be preferred
      return isDateValid ? date.toString() : '';
    }
  }, [date, options, forceEnglishDate, displayLocale]);

  return formatDateAndOrTime();
}

/**
 * Format the given number, into a regional & context based string representation
 * `formatNumber()` is internally created as a memoized callback function which returns the formatted string
 * The return value of formatNumber() is returned as the result of this hook.
 *
 * @param {number} number - number to format
 * @param {object} options - context the number will be used in
 * @param {boolean} forceEnglishNumber - whether or not to assign the default locale to the number
 *
 * @returns {string} - string type formatted date
 */
export function useFormatNumber({
  number,
  options,
  forceEnglishNumber = false,
}) {
  const { state } = useMcIntl();
  const { displayLocale } = state;

  const formatNumber = useCallback(() => {
    try {
      // for full list of options: https://www.w3schools.com/jsref/jsref_tolocalestring_number.asp
      const displayLocaleToUse = forceEnglishNumber
        ? SUPPORTED_LOCALES.DEFAULT
        : displayLocale;
      if (options) {
        return number.toLocaleString(displayLocaleToUse, options);
      }
      return number.toLocaleString(displayLocaleToUse);
    } catch (error) {
      const isNumberValid = typeof number === 'number';
      const logText = isNumberValid
        ? 'translationUtils: error thrown when trying to format number to string'
        : 'translationUtils: error thrown for invalid number param';
      console.warn(logText, error);
      // NOTE fallback text may be preferred
      return isNumberValid ? number.toString() : '';
    }
  }, [number, options, forceEnglishNumber, displayLocale]);

  return formatNumber();
}

// error boundary for i18n-formatting library based utilities
function formattingLibErrorBoundary(func, message) {
  try {
    return func();
  } catch (error) {
    console.warn('formatting library error:', message);
    return '';
  }
}

/**
 * Newer method below this - temporary during flag ramping: i18n.use_callback_use_localize_date
 *
 * @param {string} date - date to format
 * @param {string} length - desired length of formatted date
 * @param {boolean} includeTime - whether or not to include time in formatted date. defaults to false. time only included on short & full lengths
 *
 * @returns {string} - string type formatted date
 */
export function useLegacyLocalizeDate(date, length, includeTime) {
  const { datePatternPreference } = useUserData();
  const { state } = useMcIntl();

  if (length === 'timeOnly') {
    const dateString = formattingLibErrorBoundary(
      useDateLocalizer.bind(
        null,
        date,
        state.displayLocale,
        datePatternPreference,
        'short',
        true,
      ),
      'Failed trying to format date &/ time',
    );
    return dateString.split(' ').pop();
  }

  return formattingLibErrorBoundary(
    useDateLocalizer.bind(
      null,
      date,
      state.displayLocale,
      datePatternPreference,
      length,
      includeTime,
    ),
    'Failed trying to format date &/ time',
  );
}

/**
 * Format the given date text, into the user's preferred date format.
 * `localizeDate()` is internally created as a memoized callback function which returns the localized date text.
 * The return value of localizeDate() is returned as the result of this hook.
 *
 * @param {string|number} date - date to format
 * @param {string} length - desired length of formatted date
 * @param {boolean} includeTime - whether or not to include time in formatted date. defaults to false. time only included on short & full lengths
 *
 * @returns {string} - string type formatted date
 */
export function useLocalizeDate(date, length, includeTime) {
  const { datePatternPreference } = useUserData();
  const { state } = useMcIntl();

  const localizeDate = useCallback(() => {
    if (length === 'timeOnly') {
      const dateString = formattingLibErrorBoundary(
        useDateLocalizer.bind(
          null,
          date,
          state.displayLocale,
          datePatternPreference,
          'short',
          true,
        ),
        'Failed trying to format date &/ time',
      );
      return dateString.split(' ').pop();
    }

    return formattingLibErrorBoundary(
      useDateLocalizer.bind(
        null,
        date,
        state.displayLocale,
        datePatternPreference,
        length,
        includeTime,
      ),
      'Failed trying to format date &/ time',
    );
  }, [date, length, includeTime, datePatternPreference, state]);

  return localizeDate();
}

useTranslateMessage.propTypes = {
  // Most of the time we just need a string, but <FormattedMessage /> returns a component wrapped in <React.Fragment>
  //  which can be handy for composing components
  // https://formatjs.io/docs/react-intl/components/#usage
  asComponent: PropTypes.bool,
  defaultMessage: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // Either for testing purposes or in conjunction with flagging logic, we want to use the defaultMessage
  //  rather than fetching a translation.
  forceDefaultMessage: PropTypes.bool,
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

useFormatDateAndOrTime.propTypes = {
  date: PropTypes.object.isRequired,
  forceEnglishDate: PropTypes.bool,
  options: PropTypes.shape({
    dateStyle: PropTypes.string,
    day: PropTypes.string,
    hour: PropTypes.string,
    minute: PropTypes.string,
    month: PropTypes.string,
    second: PropTypes.string,
    timeStyle: PropTypes.string,
    timeZoneName: PropTypes.string,
    weekday: PropTypes.string,
    year: PropTypes.string,
  }),
};

useFormatNumber.propTypes = {
  forceEnglishNumber: PropTypes.bool,
  number: PropTypes.object.isRequired,
  options: PropTypes.shape({
    currency: PropTypes.string,
    currencyDisplay: PropTypes.string,
    maximumFractionDigits: PropTypes.number,
    maximumSignificantDigits: PropTypes.number,
    minimumFractionDigits: PropTypes.number,
    minimumIntegerDigits: PropTypes.number,
    minimumSignificantDigits: PropTypes.number,
    style: PropTypes.string,
  }),
};

useLocalizeDate.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  includeTime: PropTypes.bool.isRequired,
  length: PropTypes.string.isRequired,
};
