/**
 * This is intended only for INTLX Team
 * By design, this requires the page to have a script
 * tag of type "application/json" with the id of "js-config".
 */

const TRANSLATION_ELEMENT_ID = 'js-translation-data';
let translations = {};

/**
 * Parse and update the translations object using data stored in the html on page load,
 * When parsed, the data is removed from the html to reduce page size
 *
 * @returns {void}
 */
export function updateTranslations() {
  let configElement;
  try {
    configElement = document.getElementById(TRANSLATION_ELEMENT_ID);
    if (configElement?.textContent) {
      translations = { ...JSON.parse(configElement.textContent) };
      configElement.innerText = '';
    }
  } catch {
    if (__DEV__ && configElement) {
      throw new Error(
        '@mc/translation-data: Expected JSON, received: ' +
          configElement.textContent,
      );
    } else if (__DEV__) {
      throw new Error('@mc/translation-data: Translations Element not found');
    }
  }
}

/**
 * Return the currently available translations for the current page,
 * If not yet available, update then return the translations object
 *
 * @returns {?Object}
 */
export function readTranslations() {
  if (Object.keys(translations).length) {
    return translations;
  }
  updateTranslations();
  return translations;
}
