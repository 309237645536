const THROTTLER_OPTIONS = {
  defaultWindowLength: 2, //Time in seconds
  defaultK: 3,
  maximumFrequency: 11,
  throttleCalculation: false, // true enables calculated throttle based on accepts / denies
};
const requestsMap = new Map();
const URL_EXCEPTIONS = [
  '/content/upload',
  '/content/folders/view',
  '/content/list',
  '/email/editor/edit',
  '/email/templates/editor/edit',
];

const diffInSeconds = (a: $TSFixMe, b: $TSFixMe) => {
  return Math.round((a.valueOf() - b.valueOf()) / 1000);
};

/**
 * Sanitize URL will strip #abc parameters to keep url requests combined
 *
 * @param {string} url Original request URL
 * @return {string} Sanitized url string
 */
function sanitizeUrl(url: $TSFixMe) {
  const cleanUrl = url.split('?')[0];
  return cleanUrl;
}

/**
 * Clean up entries older than the windowLength time
 *
 * @param {Number} windowLength Length of time tracked in THROTTLER_OPTIONS
 * @return {void}
 */
function cleanUpWindowListener(windowLength: $TSFixMe) {
  requestsMap.forEach(({ head }, key) => {
    if (diffInSeconds(new Date(), head) >= windowLength) {
      requestsMap.delete(key);
    }
  });
}

/**
 * Create the mapped default values for all requests in the window
 *
 * @param {Date} now Time of request
 * @param {Number} windowLength Length of time tracked in THROTTLER_OPTIONS
 * @return {Object} Request window per second
 */
function requestWindowListener(now: $TSFixMe, windowLength: $TSFixMe) {
  return {
    head: now,
    values: new Array(windowLength).fill({
      requests: 0,
      accepts: 0,
      time: null,
    }),
  };
}

/**
 * Create the mapped default values for all requests in the window
 *
 * @param {string} url Request URL
 * @param {Date} now Time of request
 * @return {void}
 */
function requestMapper(url: $TSFixMe, now: $TSFixMe) {
  if (!requestsMap.has(url)) {
    requestsMap.set(
      url,
      requestWindowListener(now, THROTTLER_OPTIONS.defaultWindowLength),
    );
  }
  // Update requests total
  updateRequestValues(
    url,
    now,
    THROTTLER_OPTIONS.defaultWindowLength,
    true,
    false,
  );
}

/**
 * Get the sum of requests and accepted requests in windowLength
 *
 * @param {Object} requestWindow Specific URL's request window
 * @param {Date} now Time of request
 * @param {Number} windowLength Length of time tracked in THROTTLER_OPTIONS
 * @return {Array} [Requests, Accepts]
 */
function getRequestValues(
  requestWindow: $TSFixMe,
  now: $TSFixMe,
  windowLength: $TSFixMe,
) {
  const reqValues = requestWindow.values;
  return reqValues.reduce(
    function(acc: $TSFixMe, val: $TSFixMe) {
      const time = val.time,
        requests = val.requests,
        accepts = val.accepts;

      if (time && diffInSeconds(now, time) < windowLength) {
        return [acc[0] + requests, acc[1] + accepts];
      }
      return acc;
    },
    [0, 0],
  );
}

/**
 * Update Requests/Accepts Object
 * This is used during requestMapper (requests) and in the fetch response (accepts)
 *
 * @param {string} url Request URL
 * @param {Date} now Time of request
 * @param {string} windowLength Length of time tracked in THROTTLER_OPTIONS
 * @param {Boolean} includeRequests Logic to add to the sum of requests
 * @param {Boolean} includeAccepts Logic to add to the sum of accepts
 * @return {void}
 */
function updateRequestValues(
  url: $TSFixMe,
  now: $TSFixMe,
  windowLength: $TSFixMe,
  includeRequests = false,
  includeAccepts = false,
) {
  const requestWindow = requestsMap.get(url);
  if (requestWindow) {
    const { head, values: reqValues } = requestWindow;
    const diffFromHead = diffInSeconds(now, head);
    // If diffFromHead negative then skip record, prevents overly clicking navigation issues
    if (diffFromHead < 0) {
      return;
    }
    if (diffFromHead >= windowLength) {
      reqValues[0] = {
        requests: includeRequests ? 1 : 0,
        accepts: includeAccepts ? 1 : 0,
        time: now,
      };
      requestsMap.set(url, { head: now, values: reqValues });
    } else {
      const currentVal = reqValues[diffFromHead];
      reqValues[diffFromHead] = {
        requests: includeRequests
          ? currentVal.requests + 1
          : currentVal.requests,
        accepts: includeAccepts ? currentVal.accepts + 1 : currentVal.accepts,
        time: now,
      };
    }
  }
}

/**
 * Bulk of throttle logic
 * Based on settings, calculate throttle or use THROTTLER_OPTIONS.maximumFrequency
 *
 * @param {string} url Request URL
 * @return {Object} [shouldThrottle, updateAccepts]
 */
function throttler(url: $TSFixMe) {
  // Clean up per windowLength setting
  cleanUpWindowListener(THROTTLER_OPTIONS.defaultWindowLength);
  const now = new Date();
  requestMapper(url, now);
  const requestWindow = requestsMap.get(url);
  const [requests, accepts] = getRequestValues(
    requestWindow,
    now,
    THROTTLER_OPTIONS.defaultWindowLength,
  );

  // Allow calculated math for throttle instead of maximum frequency
  if (THROTTLER_OPTIONS.throttleCalculation) {
    const chanceOfThrottle = Math.max(
      0,
      (requests - THROTTLER_OPTIONS.defaultK * accepts) / (requests + 1),
    );
    const random = Math.random();
    if (random < chanceOfThrottle) {
      return [
        true,
        function(x: $TSFixMe) {
          return x;
        },
      ];
    }
  }
  // Settings for maximum repeated requests before throttle
  if (requests >= THROTTLER_OPTIONS.maximumFrequency) {
    return [
      true,
      function(x: $TSFixMe) {
        return x;
      },
    ];
  }
  // If not throttled, updateRequestValues and track accepted requests
  return [
    false,
    function(trackAccepts: $TSFixMe) {
      return updateRequestValues(
        url,
        now,
        THROTTLER_OPTIONS.defaultWindowLength,
        false,
        trackAccepts,
      );
    },
  ];
}

/**
 * Exported function used in client http calls
 *
 * @param {string} url Request URL
 * @param {Object} options Original options from client http (method, headers, etc.)
 * @return {Promise} Returns Promise or rejected promise
 */
export default function throttledFetch(url: $TSFixMe, options = {}) {
  const throttleUrl = sanitizeUrl(url),
    throttle400s = false,
    throttleThresholdCode = throttle400s ? 400 : 500;
  let allowThrottling = true,
    updateAccepts: $TSFixMe;
  // Exception URLs not applicable to throttle i.e. logs or tracking.
  if (URL_EXCEPTIONS.includes(throttleUrl)) {
    allowThrottling = false;
  }

  try {
    const controller = new AbortController();
    const signal = controller.signal;
    if (allowThrottling) {
      const requestThrottler = throttler(throttleUrl);
      const shouldThrottle = requestThrottler[0];
      updateAccepts = requestThrottler[1];
      if (shouldThrottle) {
        controller.abort('The request was aborted due to throttle limit.');
        // Bugsnag to record message and throttle url
        const bugsnagError = {
          message: 'Promise rejected due to throttle limit.',
          requestedPath: throttleUrl,
        };
        return Promise.reject(bugsnagError);
      }
    }
    //Abort requests signal added to options
    const requestOptions = Object.assign(options, { signal: signal });
    // All requests will go through this fetch call
    const res = fetch(url, requestOptions).then((response) => {
      // If allowed throttle update accepts, else pass through as a standard request
      if (allowThrottling) {
        // Update accepted requests if the response less than 500 (2xx, 3xx, 4xx)
        // eslint-disable-next-line no-unused-expressions
        if (response?.status < throttleThresholdCode) {
          updateAccepts(true);
        }
      }
      return response;
    });
    return res;
  } catch (err) {
    if ((err as $TSFixMe).name === 'AbortError') {
      throw err;
    } else {
      // eslint-disable-next-line no-unused-expressions
      allowThrottling && updateAccepts(false);
      throw err;
    }
  }
}
