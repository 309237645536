import React, {
  useContext,
  createContext,
  useReducer,
  useState,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, useIntl } from 'react-intl';

import {
  MC_INTL_EVENT_NAMES,
  createEventListenerObj,
} from '@mc/internationalization/events';
import { lcpObserver, fcpObserver } from '@mc/paint-logger';
import {
  getTranslationsBE,
  getDisplayLocale,
  setDisplayLocale,
  getMergedReactTranslations,
  getFullTranslationsStatus,
} from './localeUtils';
import { updateTranslations } from './translationData';
import { SUPPORTED_LOCALES, FORCE_DEFAULT_MESSAGE_ID } from './constants';

const McIntlStateContext = createContext();
const McIntlDispatchContext = createContext();

// Name the contexts in dev tools https://reactjs.org/docs/context.html#contextdisplayname
McIntlStateContext.displayName = 'McIntlStateContext';
McIntlDispatchContext.displayName = 'McIntlDispatchContext';

// Exportable set of reducer actions to help making updates easier
const mcIntlReducerActions = {
  UPDATE_LOCALE: 'UPDATE_USER_LOCALE',
  UPDATE_DISPLAY_LOCALE: 'UPDATE_DISPLAY_LOCALE',
  UPDATE_LOCALE_TRANSLATIONS: 'UPDATE_LOCALE_TRANSLATIONS',
};

const reducer = (state, action) => {
  // We do not want to throw in the reducer because it will break the page
  // If locale or translations are null, we need to address it in the McIntlProvider useEffect
  // because McIntlProvider will silence errors in prod, while still throwing
  switch (action.type) {
    case mcIntlReducerActions.UPDATE_LOCALE: {
      // keep the state the same till the page reloads and the new locale and translations are loaded
      //   it would look awkward for part of the app to update locales before the refresh happens.
      if (window.i18n_intl_updated_state_values) {
        return {
          ...state,
          locale: action.locale || SUPPORTED_LOCALES.DEFAULT,
          needsRefresh: true,
        };
      }
      return {
        ...state,
        locale: action.locale || SUPPORTED_LOCALES.DEFAULT,
        needsRefresh: true,
        fullTranslationsLoaded: false,
      };
    }

    case mcIntlReducerActions.UPDATE_DISPLAY_LOCALE: {
      if (window.i18n_intl_updated_state_values) {
        return {
          ...state,
          displayLocale: action.displayLocale || SUPPORTED_LOCALES.DEFAULT,
          localeTranslations: action.localeTranslations || {},
        };
      }
      return {
        ...state,
        displayLocale: action.displayLocale || SUPPORTED_LOCALES.DEFAULT,
        localeTranslations: action.localeTranslations || {},
        fullTranslationsLoaded: action.fullTranslationsLoaded || false,
      };
    }

    case mcIntlReducerActions.UPDATE_LOCALE_TRANSLATIONS: {
      if (window.i18n_intl_updated_state_values) {
        return {
          ...state,
          localeTranslations: {
            ...state.localeTranslations,
            ...(action.localeTranslations || {}),
          },
        };
      }
      return {
        ...state,
        localeTranslations: {
          ...state.localeTranslations,
          ...(action.localeTranslations || {}),
        },
        fullTranslationsLoaded:
          state.fullTranslationsLoaded ||
          action.fullTranslationsLoaded ||
          false,
      };
    }

    default:
      throw new Error('Unexpected McIntlProvider action');
  }
};

const lazyLoadedPathnames = ['/', '/campaign-manager/', '/campaigns/'];

const initialState = {
  // locale -> The raw locale. Note: We might not have the translations for this locale,
  //   so use displayLocale if you are wanting the locale for the displayed translation
  locale: null,
  // displayLocale -> Given the "locale", "displayLocale" will be a locale that we display to
  //   the user.  This is important since we might not support a particular "locale" and want to
  //   display a similar locale or english to the user instead.
  displayLocale: getDisplayLocale() || SUPPORTED_LOCALES.DEFAULT,
  // localeTranslations -> All of the key-value pairs of messages for the "displayLocale"
  localeTranslations: null,
  needsRefresh: false,
  // Thus far, the homepage and campaign-manager are the only paths that
  // load the full translations through the HTML.
  fullTranslationsLoaded:
    lazyLoadedPathnames.indexOf(window.location.pathname) === -1,
};

if (window.i18n_intl_updated_state_values) {
  // simply a work around for flagging this change, remove the prop itself above when cleaning up the flag
  delete initialState.fullTranslationsLoaded;
}

const McIntlProvider = ({ children, overrides }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...overrides,
  });

  const devMissingTranslationLocales = useRef(new Set());
  // We need a reference to for the active listener for router events so that
  //  we can then remove the listener properly when the display locale changes
  const fetchTranslationsObjRef = useRef(null);
  // NOTE replace the below value with the flagged value when cleaning up i18n_intl_updated_state_values
  const [loadingTranslations, setLoadingTranslations] = useState(false);
  const loadingTranslationsFlagged = useRef(false);
  const fullTranslationsLoadedFlagged = useRef(
    lazyLoadedPathnames.indexOf(window.location.pathname) === -1,
  );
  const currentLocation = useRef(window.location.pathname);

  const {
    locale,
    displayLocale,
    localeTranslations,
    needsRefresh,
    // Fine to keep here with i18n_intl_updated_state_values ON, NOTE this will be undefined if the flag is ON
    // NOTE - this will need to be removed during flag cleanup
    fullTranslationsLoaded,
  } = state;

  useEffect(() => {
    let lcpObserverObj, fcpObserverObj;
    // Short circuit the dispatch to prevent file importing if we are running tests.
    if (__TEST__) {
      dispatch({
        type: mcIntlReducerActions.UPDATE_DISPLAY_LOCALE,
        displayLocale: displayLocale,
        localeTranslations: localeTranslations || {},
      });
    } else {
      if (window.i18n_pageload_analytics) {
        fcpObserverObj = fcpObserver();
      }
      (async () => {
        let returnedDisplayLocale;
        let returnedLocaleTranslations;
        try {
          returnedDisplayLocale = getDisplayLocale(locale);
          returnedLocaleTranslations = getTranslationsBE();

          // If js-translation-data is not available in the DOM
          //   we can try to fetch the JSON module directly
          if (!returnedLocaleTranslations) {
            returnedLocaleTranslations = await getMergedReactTranslations(
              returnedDisplayLocale,
            );
            if (window.i18n_update_translation_data) {
              // after fetching new translations, assign them to an object in app memory and remove from html
              updateTranslations(returnedLocaleTranslations);
            }
          }
          let fullTranslationsStatus;
          if (window.i18n_intl_updated_state_values) {
            fullTranslationsLoadedFlagged.current = getFullTranslationsStatus(
              returnedLocaleTranslations,
            );
          } else {
            fullTranslationsStatus = getFullTranslationsStatus(
              returnedLocaleTranslations,
            );
          }

          const dispatchObj = {
            type: mcIntlReducerActions.UPDATE_DISPLAY_LOCALE,
            displayLocale: returnedDisplayLocale || SUPPORTED_LOCALES.DEFAULT,
            localeTranslations: returnedLocaleTranslations || {},
            fullTranslationsLoaded: fullTranslationsStatus,
          };
          if (window.i18n_intl_updated_state_values) {
            delete dispatchObj.fullTranslationsLoaded;
          }

          dispatch(dispatchObj);
        } catch {
          // on error, we'll set displayLocale to "en" and localeTranslations to empty object, with intent to use defaultMessages
          dispatch({
            type: mcIntlReducerActions.UPDATE_DISPLAY_LOCALE,
            displayLocale: SUPPORTED_LOCALES.DEFAULT,
            localeTranslations: {},
          });
        } finally {
          if (window.i18n_pageload_analytics) {
            // only log the most recently recorded lcp events after 10 seconds has elapsed
            lcpObserverObj = lcpObserver(10000);
          }
        }
      })();
    }
    return () => {
      dispatch({
        type: mcIntlReducerActions.UPDATE_DISPLAY_LOCALE,
        displayLocale: initialState.displayLocale,
        localeTranslations: null,
      });
      if (!__TEST__ && window.i18n_pageload_analytics) {
        if (lcpObserverObj) {
          lcpObserverObj.disconnect();
        }
        if (fcpObserverObj) {
          fcpObserverObj.disconnect();
        }
      }
    };
    // We only want to run this once on component load for now
    //   once we have the ability for dojo and react to update based off
    //   of a change to the displayLocale value, we can adjust this to look
    //   for a state change and update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function fetchAndAddTranslationsForLocale() {
      return async function actionListenerImpl() {
        if (window.i18n_intl_updated_state_values) {
          if (
            !__TEST__ &&
            currentLocation.current !== window.location.pathname &&
            !fullTranslationsLoadedFlagged.current &&
            !loadingTranslationsFlagged.current
          ) {
            try {
              loadingTranslationsFlagged.current = true;
              currentLocation.current = window.location.pathname;
              const returnedDisplayLocale = getDisplayLocale(locale);
              let returnedLocaleTranslations;
              let existingTranslations;

              if (window.i18n_update_translation_fetching) {
                existingTranslations = getTranslationsBE();
                const fullTranslationsStatus =
                  getFullTranslationsStatus(existingTranslations);
                // If we have the full translations already parsed for the given locale, we can skip the fetch
                returnedLocaleTranslations =
                  returnedDisplayLocale === getDisplayLocale('') &&
                  fullTranslationsStatus
                    ? existingTranslations
                    : await getMergedReactTranslations(returnedDisplayLocale);
              } else {
                returnedLocaleTranslations = await getMergedReactTranslations(
                  returnedDisplayLocale,
                );
              }

              if (Object.keys(returnedLocaleTranslations).length !== 0) {
                if (
                  window.i18n_update_translation_data &&
                  existingTranslations !== returnedLocaleTranslations
                ) {
                  // update translations in app memory and remove from page html
                  updateTranslations(returnedLocaleTranslations);
                }
                //do not return true if the translations fetch is returned empty by 403
                fullTranslationsLoadedFlagged.current = true;
                dispatch({
                  type: mcIntlReducerActions.UPDATE_LOCALE_TRANSLATIONS,
                  localeTranslations: returnedLocaleTranslations || {},
                });
              }
            } catch (e) {
              // Even though we throw an error here if we cannot fetch the translations,
              //  this will not cause the application to crash, but instead will just output to
              //  the console.
              throw new Error(
                `fetchAndAddTranslationsForLocale Failed to import translations:  ${e}`,
              );
            } finally {
              loadingTranslationsFlagged.current = false;
            }
          }
        } else if (
          !__TEST__ &&
          !fullTranslationsLoaded &&
          !loadingTranslations
        ) {
          try {
            setLoadingTranslations(true);
            const returnedDisplayLocale = getDisplayLocale(locale);
            let returnedLocaleTranslations;
            let existingTranslations;

            if (window.i18n_update_translation_fetching) {
              existingTranslations = getTranslationsBE();
              const fullTranslationsStatus =
                getFullTranslationsStatus(existingTranslations);
              // If we have the full translations already parsed for the given locale, we can skip the fetch
              returnedLocaleTranslations =
                returnedDisplayLocale === getDisplayLocale('') &&
                fullTranslationsStatus
                  ? existingTranslations
                  : await getMergedReactTranslations(returnedDisplayLocale);
            } else {
              returnedLocaleTranslations = await getMergedReactTranslations(
                returnedDisplayLocale,
              );
            }

            if (Object.keys(returnedLocaleTranslations).length !== 0) {
              if (
                window.i18n_update_translation_data &&
                existingTranslations !== returnedLocaleTranslations
              ) {
                // update translations in app memory and remove from page html
                updateTranslations(returnedLocaleTranslations);
              }
              //do not return true if the translations fetch is returned empty by 403
              dispatch({
                type: mcIntlReducerActions.UPDATE_LOCALE_TRANSLATIONS,
                localeTranslations: returnedLocaleTranslations || {},
                fullTranslationsLoaded: true,
              });
            }
          } catch (e) {
            // Even though we throw an error here if we cannot fetch the translations,
            //  this will not cause the application to crash, but instead will just output to
            //  the console.
            throw new Error(
              `fetchAndAddTranslationsForLocale Failed to import translations:  ${e}`,
            );
          } finally {
            // No matter the outcome, we want to make sure that we set loading translations to false
            setLoadingTranslations(false);
          }
        }
      };
    }

    const onActionListener = fetchAndAddTranslationsForLocale(displayLocale);
    const fetchTranslationsEventObj = createEventListenerObj(
      MC_INTL_EVENT_NAMES.fetchTranslations,
      onActionListener,
    );

    // If we currently have a reference for 'unsubscribing', lets go ahead and
    //  trigger that unsubscribe so we can subscribe with the correct 'displayLocale'
    if (fetchTranslationsObjRef.current) {
      const prevFetchTranslationsObj = fetchTranslationsObjRef.current;
      // Our version of ESLint is so old it does not support optional chaining correctly
      //  disabling this warning till an update for the package is made
      // eslint-disable-next-line no-unused-expressions
      prevFetchTranslationsObj?.unsubscribe?.();
    }

    // For every time we have the 'displayLocale' update, we need to subscribe to the fetchTranslations event
    //   and update our event listener removal reference
    fetchTranslationsEventObj.subscribe();
    fetchTranslationsObjRef.current = fetchTranslationsEventObj;

    return () => {
      // Upon unmount, lets be sure to remove the event listener
      if (fetchTranslationsObjRef.current) {
        const prevFetchTranslationsObj = fetchTranslationsObjRef.current;
        // Our version of ESLint is so old it does not support optional chaining correctly
        //  disabling this warning till an update for the package is made
        // eslint-disable-next-line no-unused-expressions
        prevFetchTranslationsObj?.unsubscribe?.();
      }
    };
  }, [
    // When the flag i18n_intl_updated_state_values is cleaned-up, we will no longer need to track:
    // fullTranslationsLoaded - replaced with useRef() tracking
    // loadingTranslations - replaced with useRef() tracking
    displayLocale,
    fullTranslationsLoaded,
    loadingTranslations,
    locale,
  ]);

  useEffect(() => {
    if (needsRefresh && typeof locale === 'string') {
      setDisplayLocale(locale)
        .then(() => window.location.reload())
        .catch((err) => {
          console.error(
            `McIntlProvider: unable to perform locale update, \n with error: ${JSON.stringify(
              err,
            )}`,
          );
        });
    }
  }, [locale, needsRefresh]);

  return (
    <McIntlStateContext.Provider value={state}>
      <McIntlDispatchContext.Provider value={dispatch}>
        <IntlProvider
          locale={state.displayLocale}
          messages={state.localeTranslations}
          onError={(error) => {
            // If we are not in dev, silence all errors.  We will still get 'throws' if they happen.
            // This behavior is consistent with the `react-intl` docs for the default behavior of `onError`
            //   https://formatjs.io/docs/react-intl/api/#onerror
            if (!__DEV__) {
              return;
            }

            // There are two scenarios where the `error.code` is `MISSING_TRANSLATION`
            // 1.  It is falling back to the `defaultMessage`
            // 2.  It is falling back to the `id`
            if (
              __DEV__ &&
              error.code === 'MISSING_TRANSLATION' &&
              // We only want to suppress errors for when a default message is being used as the fallback, if it
              //   tries to fall back to the `id`, then we want to log the error.
              error.message.includes('using default message')
            ) {
              // If we are forcing the default message via a special key we made for flagging logic, lets not print to console.
              if (error.message.includes(FORCE_DEFAULT_MESSAGE_ID)) {
                return;
              }

              // If we are falling back to the defaultMessage due to not having a translation ready,
              //   lets make sure to let the dev know, but do not spam lots of error messages to the console.
              if (state.displayLocale !== SUPPORTED_LOCALES.DEFAULT) {
                // If we have already identified that we have gotten an error missing translations in this locale, lets skip the console warnings
                if (
                  devMissingTranslationLocales.current.has(state.displayLocale)
                ) {
                  return;
                }

                devMissingTranslationLocales.current.add(state.displayLocale);
                console.warn(
                  `--- Looks like there is not a translation ready for the "${state.displayLocale}" locale ---\n`,
                  '* If you see English for some of your messages, that is probably intended 👍\n',
                  `* Be sure to test out your translation logic using the "${SUPPORTED_LOCALES.DEFAULT}" locale and ensure there are no errors\n`,
                  `* Suppressing all other 'MISSING_TRANSLATION' errors for this locale that will displaly the "defaultMessage"`,
                  '* Please reach out to #intl-eng-help with any questions.\n\n',
                  error.message,
                );

                return;
              }
            }
            // Log all other errors to console
            console.error(error);
          }}
        >
          {children}
        </IntlProvider>
      </McIntlDispatchContext.Provider>
    </McIntlStateContext.Provider>
  );
};

McIntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
  overrides: PropTypes.exact({
    locale: PropTypes.string,
    localeTranslations: PropTypes.object,
    displayLocale: PropTypes.string,
  }),
};

const useMcIntl = () => {
  const state = useContext(McIntlStateContext);
  const dispatch = useContext(McIntlDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error('useMcIntl called outside of <McIntlProvider /> context');
  }

  // Let's re-export 'react-intl' intl so that people dont need to import
  //   useMcIntl and useIntl, they need to just import one.
  const intl = useIntl();

  return { state, dispatch, mcIntlReducerActions, intl };
};

// This function can be used to ensure that we return a default message if we are outside of <McIntlProvider />
// Use cases include documentation site, plums/dot-com, and other areas where translations might be required, but
//   we are unsure of being within an <McIntlContext />
const mcIntlContextGuard = () => (transformerFunc) => (translationObj) => {
  try {
    // Test to see if we are wrapped within the <McIntlProvider />
    useMcIntl();
  } catch {
    // If useMcIntl threw an error, it means we are outside of the <McIntlProvider />
    // We should try to then just return a defaultMessage rather than attempt to do a translation
    if (!translationObj || !translationObj.defaultMessage) {
      throw new Error(
        `mcIntlContextGuard:  no "defaultMessage" was supplied within translationObj.  Cannot display content to the user`,
      );
    }
    // Just return the defaultMessage to display to the user rather than a translation
    return translationObj.defaultMessage;
  }

  return transformerFunc(translationObj);
};

export {
  McIntlProvider,
  McIntlStateContext,
  McIntlDispatchContext,
  useMcIntl,
  mcIntlReducerActions,
  mcIntlContextGuard,
  lazyLoadedPathnames,
};
