const MC_INTL_EVENT_NAMES = {
  fetchTranslations: 'mcIntlFetchTranslations',
};

function createEventListenerObj(eventName, listener) {
  function dispatch(data) {
    let event = new CustomEvent(eventName, data);
    document.dispatchEvent(event);
    event = null;
  }

  function unsubscribe() {
    document.removeEventListener(eventName, listener);
    listener = null;
  }

  return {
    eventName: eventName,
    listener: listener,
    subscribe: () => document.addEventListener(eventName, listener),
    unsubscribe: () => unsubscribe(),
    dispatch: (data) => dispatch(data),
  };
}

// Common dispatch action to send the message to fetch translations
function fetchTranslations() {
  let eventObj = createEventListenerObj(MC_INTL_EVENT_NAMES.fetchTranslations);
  eventObj.dispatch();
  eventObj = null;
}

const mcIntlEvents = {
  fetchTranslations: fetchTranslations,
};

export { createEventListenerObj, MC_INTL_EVENT_NAMES, mcIntlEvents };
